import React from 'react'
import Layout from '../components/main/Layout'

const StageDetail = () => {
  return (
    <Layout>
        <header className=" border-b">
                <div className="py-4  flex justify-between w-full">
                    <h1 className="text-xl font-light tracking-tight text-blue-900">Stage Detail</h1>
                    
                </div>
            </header>
            <main>

                <section className="text-gray-600 body-font">

                   

                </section>


            </main>
    </Layout>
  )
}

export default StageDetail