import { useState, useEffect } from 'react'
import { FaTicket, FaHandshake, FaBinoculars, FaChartColumn, FaClipboardCheck, FaClipboardUser, FaPaperPlane } from 'react-icons/fa6'

const Stepper = () => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        }, 500); // Starts the animation 500ms after component mounts
    }, []);


    return (
        <div className="w-full py-6">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-7 gap-4">
                <div>
                    <div className="relative mb-2">
                        <div className="w-10 h-10 mx-auto bg-green-500 rounded-full text-lg text-white flex items-center justify-center">

                            <FaTicket />

                        </div>
                    </div>

                    <div className="text-xs text-center md:text-base">Pre-sales</div>
                </div>

                <div>
                    <div className="relative mb-2">
                        <div className="absolute flex align-center items-center align-middle content-center" style={{
                            width: "calc(100% - 2.5rem - 1rem)",
                            top: "50%",
                            transform: "translate(-50%, -50%)"
                        }}>
                            <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                                <div className="w-0 bg-green-500 py-1 rounded" style={{ width: loaded ? "100%" : "0%", transition: "width 1s ease-in-out" }}></div>
                            </div>
                        </div>

                        <div className="w-10 h-10 mx-auto bg-green-500 rounded-full text-lg text-white flex items-center justify-center">

                            <FaHandshake />

                        </div>
                    </div>

                    <div className="text-xs text-center md:text-base">Deal</div>
                </div>

                <div>
                    <div className="relative mb-2">
                        <div className="absolute flex align-center items-center align-middle content-center" style={{ width: "calc(100% - 2.5rem - 1rem)", top: "50%", transform: "translate(-50%, -50%)" }}>
                            <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                            <div className="w-0 bg-green-500 py-1 rounded" style={{ width: loaded ? "50%" : "0%", transition: "width 1s ease-in-out" }}></div>
                            </div>
                        </div>

                        <div className="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-gray-500 flex items-center justify-center">

                            <FaBinoculars />

                        </div>
                    </div>

                    <div className="text-xs text-center md:text-base">Discovery</div>
                </div>

                <div>
                    <div className="relative mb-2">
                        <div className="absolute flex align-center items-center align-middle content-center" style={{ width: "calc(100% - 2.5rem - 1rem)", top: "50%", transform: "translate(-50%, -50%)" }}>
                            <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                                <div className="w-0 bg-green-300 py-1 rounded" style={{ width: "0%" }}></div>
                            </div>
                        </div>

                        <div className="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-gray-500 flex items-center justify-center">

                            <FaChartColumn />

                        </div>
                    </div>

                    <div className="text-xs text-center md:text-base">Development</div>
                </div>
                <div>
                    <div className="relative mb-2">
                        <div className="absolute flex align-center items-center align-middle content-center" style={{ width: "calc(100% - 2.5rem - 1rem)", top: "50%", transform: "translate(-50%, -50%)" }}>
                            <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                                <div className="w-0 bg-green-300 py-1 rounded" style={{ width: "0%" }}></div>
                            </div>
                        </div>

                        <div className="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-gray-500 flex items-center justify-center">

                            <FaClipboardCheck />

                        </div>
                    </div>

                    <div className="text-xs text-center md:text-base">QA Internal</div>
                </div>
                <div>
                    <div className="relative mb-2">
                        <div className="absolute flex align-center items-center align-middle content-center" style={{ width: "calc(100% - 2.5rem - 1rem)", top: "50%", transform: "translate(-50%, -50%)" }}>
                            <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                                <div className="w-0 bg-green-300 py-1 rounded" style={{ width: "0%" }}></div>
                            </div>
                        </div>

                        <div className="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-gray-500 flex items-center justify-center">

                            <FaClipboardUser />

                        </div>
                    </div>

                    <div className="text-xs text-center md:text-base">UAT</div>
                </div>
                <div>
                    <div className="relative mb-2">
                        <div className="absolute flex align-center items-center align-middle content-center" style={{ width: "calc(100% - 2.5rem - 1rem)", top: "50%", transform: "translate(-50%, -50%)" }}>
                            <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                                <div className="w-0 bg-green-300 py-1 rounded" style={{ width: "0%" }}></div>
                            </div>
                        </div>

                        <div className="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-gray-500 flex items-center justify-center">

                            <FaPaperPlane />

                        </div>
                    </div>

                    <div className="text-xs text-center md:text-base">Delivery</div>
                </div>
            </div>
        </div>
    )
}

export default Stepper