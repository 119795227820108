import React from 'react'
import Layout from '../components/main/Layout'

import ProjectTable from '../components/projects/ProjectTableStyle'

const Projects = () => {
  return (
    <Layout>
        <header className=" border-b">
                <div className="py-4  flex justify-between w-full">
                    <h1 className="text-xl font-light tracking-tight text-blue-900">Projects</h1>
                   
                </div>
            </header>
            <main>

                <section className="text-gray-600 body-font bg-white p-4 mb-3 rounded-md shadow-sm">

                  
                   <ProjectTable />

                </section>


            </main>

    </Layout>
  )
}

export default Projects