import React from 'react'
import DETALOGO from '../../assets/logo.png'

const Footer = () => {
  return (
    <footer className="text-gray-600 body-font fixed inset-x-0 bottom-0">
      <div className="container  py-8 mx-auto flex items-center sm:flex-row flex-col items-end justify-end ">
        <img src={DETALOGO} className=' w-10' />
       

       

      </div>
    </footer>
  )
}

export default Footer