import React from 'react'
import Layout from '../components/main/Layout'
import SideBar from '../components/main/SideBar'

const Portal = () => {
  return (
    <Layout>
     

    </Layout>
  )
}

export default Portal