import { useState } from "react";
import DETAICON from '../../assets/Favicon.png'
import DETALOGO from '../../assets/logo.png'


import { FaAngleLeft, FaHouse, FaBuffer, FaFolderTree, FaRegLightbulb, FaRectangleList, FaFileVideo, FaBrain,  FaCalculator, FaCertificate,FaUserGear, FaCode, FaTableCells } from "react-icons/fa6";
import { Link } from "react-router-dom";



const Sidebar = () => {
    const [open, setOpen] = useState(true);

    const Menus = [
        { title: "Home", icon: <FaHouse/>, url: "/portal"},
        { title: "Processes", icon:<FaBuffer/>, url: "/resourses"},
        { title: "Projects", icon: <FaFolderTree/>, url: "/projects"},
        { title: "Hacks", icon: <FaRegLightbulb />, url: "/hacks"},
        { title: "Resources", icon: <FaRectangleList />, url: "/portal"},
        { title: "Videos", icon: <FaFileVideo />, url: "/videos"},
        { title: "AI Tools", icon: <FaBrain/>, url: "/ai-tools"},
        { title: "Assessments", icon: <FaCalculator/>, url: "/assessments"},
        { title: "Certifications", icon: <FaCertificate />, url: "/certifications"},
        { title: "Training", icon: <FaUserGear />, url: "/training"},
        { title: "Codelab", icon: <FaCode />, url: "/codelab"},
        
        { title: "Templates", icon: <FaTableCells />, url: "/templates"},
        
        
      ];

  return (
   
      <div
        className={` ${
          open ? "w-56" : "w-20 "
        } bg-white h-screen p-5  pt-8 relative duration-300 rounded-lg my-2 shadow-md ml-2`}
      >
        
        <button  className={`absolute cursor-pointer -right-3 top-9 w-7  bg-white
           border-2 rounded-full text-gray-600  ${!open && "rotate-180"}`}
          onClick={() => setOpen(!open)}><FaAngleLeft /></button>
        <div className="flex gap-x-4 items-center">
          <img
            src={DETAICON}
            className={`cursor-pointer duration-500 ${open && "hidden"} ${!open && "rotate-[360deg]"} `}
            alt="Decision Foundry"
          />
          <h1
            className={`text-gray-900 origin-left font-medium text-xl duration-200 ${
              !open && "scale-0"
            }`}
          >
            <img src={DETALOGO} className="w-20"  alt="Decision Foundry" />
          </h1>
        </div>
        <ul className="pt-6">
          {Menus.map((Menu, index) => (
            <li
              key={index}
              className={`flex  rounded-md p-2 cursor-pointer hover:bg-gradient-to-r hover:from-blue-900 hover:to-blue-700 hover:text-white text-gray-800 text-sm items-center gap-x-4 
              ${Menu.gap ? "mt-6" : "mt-2"} ${
                index === 0 && "bg-light-white"
              } `}
            >
              <Link to={Menu.url} className="flex gap-2">
              <div className="group">
              {Menu.icon}
              <span className={`absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 p-1 rounded text-sm bg-gray-800 text-white opacity-0 group-hover:opacity-100 transition ${!open && "hidden"}`}>
                {Menu.title}
              </span>
            </div>
              <span className={`${!open && "hidden"} origin-left duration-200`}>
                {Menu.title}
              </span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      
  )
}

export default Sidebar