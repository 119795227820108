import React from 'react'
import {Select, SelectItem, Avatar} from "@nextui-org/react";

const SelectAvatarName = ({label,dataList,multiple,onSelectFunction}) => {
    const selectProps = multiple ? { selectionMode: 'multiple' } : {};
  return (
    <Select
      className="max-w-xs"
      label={label}
      {...selectProps}
      onChange={onSelectFunction}
    >
      {  
      dataList.map((dlist)=>
        (<SelectItem
            key={dlist.id}
            startContent={<Avatar alt={dlist.name} className="w-6 h-6" src={dlist.profilePic} />}
          >
            {dlist.name}
          </SelectItem>)
      )
      
      }
    </Select>
  )
}

export default SelectAvatarName