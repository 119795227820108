import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import Login from './pages/Login';
import Portal from './pages/Portal';
import Splash from './pages/Splash';
import Resources from './pages/Resources';
import Projects from './pages/Projects';
import Project from './pages/Project';
import AddProject from './pages/AddProject';
import StageDetail from './pages/StageDetail';



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/portal" element={<Portal />} />
        <Route path="/splash" element={<Splash />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/project" element={<Project />} />
        <Route path="/add-project" element={<AddProject />} />
        <Route path="/stage-detail" element={<StageDetail />} />
      </Routes>
    </Router>
  );
}

export default App;
