import axios from 'axios';
import React from 'react'
import { FaFingerprint } from 'react-icons/fa6'

const LoginButton = () => {
    const handleLogin = () => {
        // Redirect to the Express server for authentication
        window.location.href = 'https://deta-demo.decisionfoundry.com/api/sso/login';
    };

    return (
        <div>
            <button onClick={handleLogin} className='bg-gradient-to-r from-blue-700 to-blue-900 hover:from-blue-900 hover:to-blue-700 py-2 flex gap-2 px-4 text-gray-50 rounded-md'>
                <FaFingerprint />
                Login with Decision Foundry Email
            </button>

        </div>
    );
}

export default LoginButton