import React from 'react'
import LOGO from '../../assets/logo.png'

const Header = () => {
  return (
    <div className='border-b p-4 border-gray-400 mx-4'>
        <img src={LOGO} width={120} />
    </div>
  )
}

export default Header