import React from 'react'

const Footer = () => {
  return (
    <footer className='fixed inset-x-0 bottom-0'> 
        <p className='text-center  text-gray-600 p-4 text-sm'>&copy; 2023 DETA (Decision Foundry LLC). All rights reserved.</p>
    </footer>
  )
}

export default Footer