import React, {useState} from 'react'
import SideBar from './SideBar'
import Header from './Header'
import Footer from './Footer'
import Sidebar from './SideBar'

const Layout = ({children}) => {
  
  return (
    <>
    <div className="flex">
    <Sidebar />
    <div className="h-screen flex-1 p-7">
      <Header />
    {children}
     <Footer />
      </div>
    
     
     
   
    </div>
    </>
  )
}

export default Layout