import React from 'react'
import Layout from '../components/main/Layout'
import { FaPlus, FaFolder } from 'react-icons/fa6'

const Resources = () => {
    return (
        <Layout>
            <header className=" border-b">
                <div className="py-4  flex justify-between w-full">
                    <h1 className="text-xl font-light tracking-tight text-blue-900">Resources</h1>
                    <button className='flex inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] '> <FaPlus /> Add Resource</button>
                </div>
            </header>
            <main>

                <section className="text-gray-600 body-font">

                    <div className="flex flex-wrap -m-4">
                        <div className="lg:w-1/6 md:w-1/3 p-2 w-full">
                            <button className="block relative h-48 rounded overflow-hidden ">
                                <div className=' text-blue-900 text-[100px] '>
                                    <FaFolder className=' ' />
                                </div>
                                <span className='text-sm font-bold text-blue-950'>BI</span>
                            </button>

                        </div>
                        <div className="lg:w-1/6 md:w-1/3 p-2 w-full">
                            <button className="block relative h-48 rounded overflow-hidden">
                                <div className=' text-blue-900 text-[100px] '>
                                    <FaFolder className=' ' />
                                </div>
                                <span className='text-sm font-bold text-blue-950'>CS</span>
                            </button>

                        </div>
                        <div className="lg:w-1/6 md:w-1/3 p-2 w-full">
                            <button className="block relative h-48 rounded overflow-hidden">
                                <div className=' text-blue-900 text-[100px] '>
                                    <FaFolder className=' ' />
                                </div>
                                <span className='text-sm font-bold text-blue-950'>Engineering</span>
                            </button>

                        </div>
                        <div className="lg:w-1/6 md:w-1/3 p-2 w-full">
                            <button className="block relative h-48 rounded overflow-hidden">
                                <div className=' text-blue-900 text-[100px] '>
                                    <FaFolder className=' ' />
                                </div>
                                <span className='text-sm font-bold text-blue-950'>Finance</span>
                            </button>

                        </div>
                        <div className="lg:w-1/6 md:w-1/3 p-2 w-full">
                            <button className="block relative h-48 rounded overflow-hidden">
                                <div className=' text-blue-900 text-[100px] '>
                                    <FaFolder className=' ' />
                                </div>
                                <span className='text-sm font-bold text-blue-950'>HR</span>
                            </button>

                        </div>
                        <div className="lg:w-1/6 md:w-1/3 p-2 w-full">
                            <button className="block relative h-48 rounded overflow-hidden">
                                <div className=' text-blue-900 text-[100px] '>
                                    <FaFolder className=' ' />
                                </div>
                                <span className='text-sm font-bold text-blue-950'>IT & Infosec</span>
                            </button>

                        </div>
                        <div className="lg:w-1/6 md:w-1/3 p-2 w-full">
                            <button className="block relative h-48 rounded overflow-hidden">
                                <div className=' text-blue-900 text-[100px] '>
                                    <FaFolder className=' ' />
                                </div>
                                <span className='text-sm font-bold text-blue-950'>MIS</span>
                            </button>

                        </div>
                        <div className="lg:w-1/6 md:w-1/3 p-2 w-full">
                            <button className="block relative h-48 rounded overflow-hidden items-center justify-center">
                                <div className=' text-blue-900 text-[100px] '>
                                    <FaFolder className=' ' />
                                </div>
                                <span className='text-sm font-bold text-blue-950'>Sales & Marketing</span>
                            </button>

                        </div>


                    </div>

                </section>


            </main>
        </Layout>
    )
}

export default Resources