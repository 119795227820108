import React from 'react';
import Header from '../components/signin/Header';
import Footer from '../components/signin/Footer';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaLock } from 'react-icons/fa6';
import ImageSlider from '../components/signin/ImageSlider';
import LoginButton from '../components/signin/LoginButton';

const Login = () => {
    return (
        <div className={`bg-no-repeat bg-right  h-screen bg-[url('https://res.cloudinary.com/ddv9pyxai/image/upload/v1694073443/DETA2.0/BG_Element_gc4r9b.png')] bg-[#f2f2f2]`}>
            <Header />
            <div className='flex  w-full'>
                <div className='item-left justify-start w-1/2 mx-24 mt-18'>
                    <div className="flex min-h-full flex-1 flex-col justify-center  lg:px-8">
                       

                        <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-sm">
                            <LoginButton />

                           
                        </div>
                    </div>
                </div>
                <div className='item-left justify-start w-1/4 mx-4 mt-20'>
                    <ImageSlider />
                </div>
            </div>

            <Footer />

        </div>
    )
}

export default Login;
