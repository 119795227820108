import React from 'react'
import Layout from '../components/main/Layout'

import AddProjectForm from '../components/addProject/AddProjectForm'

const AddProject = () => {
  return (
    <Layout>
        <header className=" border-b">
                <div className="py-4  flex justify-between w-full">
                    <h1 className="text-xl font-light tracking-tight text-blue-900">Add Project</h1>
                   
                </div>
            </header>
            <main>

                <section className="text-gray-600 body-font">
                   <div className='w-1/2 p-3 bg-white mt-3 rounded-md shadow-sm'>
                        <AddProjectForm />
                   </div>
                  

                </section>


            </main>

    </Layout>
  )
}

export default AddProject