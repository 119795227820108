import React from 'react';
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import SliderImage from '../../assets/slider_image.png'

function ImageSlider() {
  const settings = {
    dots: true, // This will show bullet navigation
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:false
  };

  return (
    <Slider {...settings}>
      <div>
        <img src={SliderImage} alt="Image 1" className='rounded-lg' />
      </div>
      <div>
        <img src={SliderImage} alt="Image 2" className='rounded-lg' />
      </div>
      <div>
        <img src={SliderImage} alt="Image 3" className='rounded-lg' />
      </div>
      <div>
        <img src={SliderImage} alt="Image 2" className='rounded-lg' />
      </div>
      <div>
        <img src={SliderImage} alt="Image 3" className='rounded-lg' />
      </div>
    </Slider>
  );
}

export default ImageSlider;
