import React from 'react'
import { Avatar, AvatarGroup } from "@nextui-org/react";
import {Tooltip, Chip
} from "@nextui-org/react";


const ProjectDetail = () => {
    return (
        <div className='w-full '>

            <div className='w-full flex mb-3'>

                <div className='w-1/2'>
                    <div className='w-full flex'>
                        <div className='w-1/4 text-sm font-bold'>Project Name</div>
                        <div className='w-3/4 text-sm'>Project Name will come here</div>
                    </div>
                </div>
                <div className='w-1/2'>
                    <div className='w-full flex'>
                        <div className='w-1/4 text-sm font-bold'>Department</div>
                        <div className='w-3/4 text-sm'>Business Intelligence</div>
                    </div>
                </div>
            </div>
            <div className='w-full flex mb-3'>

                <div className='w-1/2'>
                    <div className='w-full flex'>
                    <div className='w-1/4 text-sm font-bold'>Owner</div>
                        <div className='w-3/4 text-sm flex gap-2'>
                        <Avatar className="w-6 h-6 text-tiny" src="https://i.pravatar.cc/150?img=10" /> <p className="text-xs mt-1">John Doe</p>
                        </div>
                    </div>
                </div>
                <div className='w-1/2'>
                    <div className='w-full flex'>
                    <div className='w-1/4 text-sm font-bold'>Project Manager</div>
                    <div className='w-3/4 text-sm flex gap-2'>
                        <Avatar className="w-6 h-6 text-tiny" src="https://i.pravatar.cc/150?img=7" /> <p className="text-xs mt-1">John Doe</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full flex mb-3'>

                <div className='w-1/2'>
                    <div className='w-full flex'>
                    <div className='w-1/4 text-sm font-bold'>Customer Success</div>
                    <div className='w-3/4 text-sm flex gap-2'>
                        <Avatar className="w-6 h-6 text-tiny" src="https://i.pravatar.cc/150?img=5" /> <p className="text-xs mt-1">John Doe</p>
                        </div>
                    </div>
                </div>
                <div className='w-1/2'>
                    <div className='w-full flex'>
                    <div className='w-1/4 text-sm font-bold'>Developers</div>
                        <div className='w-3/4 text-sm'>
                        <AvatarGroup max={3} size="sm" className='items-start justify-start'>
                          <Tooltip content="User name" className="">
                            <Avatar src="https://i.pravatar.cc/150?img=29" className="w-6 h-6 text-tiny cursor-pointer" />
                          </Tooltip>
                          <Tooltip content="User name">
                            <Avatar src="https://i.pravatar.cc/150?img=50" className="w-6 h-6 text-tiny cursor-pointer" />
                          </Tooltip>
                          <Tooltip content="User name">
                            <Avatar src="https://i.pravatar.cc/150?img=8" className="w-6 h-6 text-tiny cursor-pointer" />
                          </Tooltip>

                        </AvatarGroup>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full flex mb-3'>

                <div className='w-1/2'>
                    <div className='w-full flex'>
                    <div className='w-1/4 text-sm font-bold'>Other Users</div>
                        <div className='w-3/4 text-sm items-start justify-start'>
                        <AvatarGroup max={3} size="sm" className='items-start justify-start'>
                          <Tooltip content="User name" className="">
                            <Avatar src="https://i.pravatar.cc/150?img=29" className="w-6 h-6 text-tiny cursor-pointer" />
                          </Tooltip>
                          <Tooltip content="User name">
                            <Avatar src="https://i.pravatar.cc/150?img=50" className="w-6 h-6 text-tiny cursor-pointer" />
                          </Tooltip>
                          <Tooltip content="User name">
                            <Avatar src="https://i.pravatar.cc/150?img=8" className="w-6 h-6 text-tiny cursor-pointer" />
                          </Tooltip>

                        </AvatarGroup>
                        </div>
                    </div>
                </div>
                <div className='w-1/2'>
                    <div className='w-full flex'>
                    <div className='w-1/4 text-sm font-bold'>Stage / Status</div>
                        <div className='w-3/4 text-sm'><Chip color="success" className='text-white'>Deal</Chip></div>
                    </div>
                </div>
            </div>




        </div>
    )
}

export default ProjectDetail