import React from 'react'
import Layout from '../components/main/Layout'
import Stepper from '../components/project/Stepper'
import ProjectDetail from '../components/project/ProjectDetail'
import {FaPencil} from "react-icons/fa6"


const Project = () => {
    return (
        <Layout>
            <header className=" border-b">
                <div className="py-4  flex justify-between w-full">
                    <h1 className="text-xl font-light tracking-tight text-blue-900">Project Detail</h1>
                    <button className='flex gap-1'><FaPencil /> Edit</button>
                </div>
            </header>
            <main>

                <section className="text-gray-600 body-font">

                    <div className='w-full bg-white mt-3 mb-3 rounded-md shadow-md p-3'>


                       <Stepper />
                    </div>

                    <div className='w-full bg-white mt-3 mb-3 rounded-md shadow-md p-3'>


                      <ProjectDetail />
                    </div>


                </section>


            </main>
        </Layout>
    )
}

export default Project