import React, { useRef, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';




const Splash = () => {

    // const controls = useAnimation();
    // const pathRefs = useRef([]);

    const path_data_list = [
        'M37.6,33.7h188.6c127.9,0,215.9,72.7,215.9,191.3s-88,191.3-215.9,191.3H37.6V33.7z M220.7,315.7    c54.1,0,91.3-32.2,91.3-90.7s-37.2-90.7-91.3-90.7h-54.1v181.5H220.7z',
        'M716.9,319v97.3H339.1c0,0,119.9-27.9,124-191.3c3.8-152.4-124-191.3-124-191.3h291.8l61.4,97.3H525.6v44.8    h161.8v92.9H525.6V319H716.9z',
        'M769.6,130.6h-56.5l-61.4-96.9h365.6l-43,96.9h-75.8v177.2l-47.4,108.4h-81.5V130.6z',
        'M1173.6,349.6h-145.4l-25.7,66.7H871.3l167.2-382.6h126.8l167.2,382.6h-133.4L1173.6,349.6z M1138.1,256.7    l-37.2-96.2l-37.2,96.2H1138.1z'
      ];


    //   useEffect(() => {
    //     pathRefs.current.forEach((path, index) => {
    //         const length = path.getTotalLength();
    //         path.style.strokeDasharray = length;
    //         path.style.strokeDashoffset = length;

    //         controls.start({ strokeDashoffset: 0 });
    //     });
    // }, [controls]);

    // This function will compute the length of the path
    const computePathLength = (pathData) => {
        const tempPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        tempPath.setAttribute('d', pathData);
        return tempPath.getTotalLength();
    };


  return (
    <div className='w-full h-screen bg-black items-center justify-center'>
         <div className='w-1/2 ml-auto mr-auto pt-40 h-screen items-center justify-center'>
            <svg viewBox="0 0 1366 450.5" xmlns="http://www.w3.org/2000/svg">
                {path_data_list.map((d, index) => {
                    const length = computePathLength(d);
                    return (
                        <motion.path
                            key={index}
                            d={d}
                            fill="none"
                            stroke="white"
                            strokeWidth="3"
                            initial={{ strokeDashoffset: length }}
                            animate={{ strokeDashoffset: 0 }}
                            transition={{ duration: 2, ease: "easeInOut", delay: index * 0.5 }}
                            style={{ strokeDasharray: length }}
                        />
                    );
                })}
            </svg>
            </div>
        </div>
  )
}

export default Splash