import {useState} from 'react'
import { Input, Button, Select, SelectItem, Textarea } from "@nextui-org/react";
import SelectAvatarName from './SelectAvatarName';

const AddProjectForm = () => {

    const [name, setName] = useState("");
    const [department, setDepartment] = useState("");
    const [short_description, setShortDescription] = useState("");
    const [owner, setOwner] = useState("");
    const [project_manager, setProjectManager] = useState("");
    const [customer_success, setCustomerSuccess] = useState("");
    const [developers, setDevelopers] = useState("");
    const [other_users, setOtherUsers] = useState("");
    const [status, setStatus] = useState("");


    // const departments = [
    //     "Business Intelligent and Analytics", 
    //     "Engineering", 
    //     "Information Technology", 
    //     "People Function",
    //     "Customer Success"
    // ]


    const departments = [
        {
            value: "BIA",
            label: "Business Intelligent and Analytics"
        },
        {
            value: "Engineering",
            label: "Engineering"
        },
        {
            value: "IT",
            label: "Information Technology"
        },
        {
            value: "people-function",
            label: "People Function"
        },
        {
            value: "CS",
            label: "Customer Success"
        },
    ]


    const dataList = [
        {
            id: "1",
            name: "John doe",
            profilePic: "https://i.pravatar.cc/150?img=10"
        },
        {
            id: "2",
            name: "Jane doe",
            profilePic: "https://i.pravatar.cc/150?img=11"
        },
        {
            id: "3",
            name: "John Smith",
            profilePic: "https://i.pravatar.cc/150?img=12"
        },
        {
            id: "4",
            name: "Alan doe",
            profilePic: "https://i.pravatar.cc/150?img=13"
        },
        {
            id: "5",
            name: "Sarah Johnson",
            profilePic: "https://i.pravatar.cc/150?img=14"
        },
    ];


    const projectStages = [
        {
            value: "pre-sales",
            label: "Pre-sales"
        },
        {
            value: "deal",
            label: "Deal"
        },
        {
            value: "discovery",
            label: "Discovery"
        },
        {
            value: "development",
            label: "Development"
        },
        {
            value: "qa-internal",
            label: "QA Internal"
        },
        {
            value: "uat",
            label: "UAT"
        },
        {
            value: "delivery",
            label: "Delivery"
        }
    ]


    const onSelectName =(e) => setName(e.target.value)
    const onSelectDepartment =(e) => setDepartment(e.target.value)
    const onSelectShortDescription =(e) => setShortDescription(e.target.value)
    const onSelectProjectOwner =(e) => setOwner(e.target.value)
    const onSelectProjectManager =(e) => setProjectManager(e.target.value)
    const onSelectCustomerSuccess =(e) => setCustomerSuccess(e.target.value)
    const onSelectDevelopers=(e) => setDevelopers(e.target.value)
    const onSelectOtherUsers =(e) => setOtherUsers(e.target.value)
    const onSelectStatus =(e) => setStatus(e.target.value)
    
    
   console.table({name,department,short_description,owner,project_manager,customer_success,developers,other_users,status})


   const onHandleProjectSubmit = (e) => {
    e.preventdefault();
    
    const projectObject = {
        name,
        department,
        short_description,
        owner,
        project_manager,
        customer_success,
        developers,
        other_users,
        status

    };

    console.log(projectObject)
   }
    
    return (
        <form onSubmit={onHandleProjectSubmit}>
        <div className="grid grid-cols-2 gap-4">
           
            <div className=" p-1">
                <Input type="text" label="Project Name" onChange={onSelectName} />
            </div>
            <div className="p-1">
                <Select
                    isRequired
                    label="Department"
                    placeholder="Select department"
                    defaultSelectedKeys={["BIA"]}
                    className="max-w-xs"
                    onChange={onSelectDepartment}
                >
                    {departments.map((department) => (
                        <SelectItem key={department.value} value={department.value}>
                            {department.label}
                        </SelectItem>
                    ))}
                </Select>
            </div>
            <div className="col-span-2 p-1">
                <Textarea
                    label="Short Description"
                    labelPlacement="inside"
                    placeholder="Enter your project description"
                    className="w-full"
                    onChange={onSelectShortDescription}
                />
            </div>

            <div className="p-1">
                <SelectAvatarName label="Project Owner" dataList={dataList} multiple={false} onSelectFunction={onSelectProjectOwner} />
            </div>
            <div className="p-1">
                <SelectAvatarName label="Project Manager" dataList={dataList} multiple={false} onSelectFunction={onSelectProjectManager} />
            </div>
            <div className="p-1">
                <SelectAvatarName label="Customer Success" dataList={dataList} multiple={false} onSelectFunction={onSelectCustomerSuccess} />
            </div>
            <div className="p-1">
                <SelectAvatarName label="Developers" dataList={dataList} multiple={true} onSelectFunction={onSelectDevelopers} />
            </div>
            <div className="p-1">
                <SelectAvatarName label="Other Users" dataList={dataList} multiple={true} onSelectFunction={onSelectOtherUsers} />
            </div>
            <div className="p-1">
                <Select
                    label="Project Stage"
                    placeholder="Select current stage"
                    className="w-full"
                    onChange={onSelectStatus}
                >
                    {projectStages.map((stage) => (
                        <SelectItem key={stage.value} value={stage.value}>
                            {stage.label}
                        </SelectItem>
                    ))}
                </Select>
            </div>
            <div className=" col-span-2 p-1">
                <button type='submit' className='bg-blue-900 text-white px-2 py-1 rounded-md cursor-pointer' name='submit'  >
                    ADD PROJECT
                </button>
            </div>
           
        </div>
        </form>
    )
}

export default AddProjectForm